import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useApiData } from '../../../../../contexts/api-data';
import { useTerritoriesState } from '../../../../../components/manage/alertario/stations-list/states/territories';

import {
    getVariablesAndOperations,
    getOperationLabel,
    formatLocalOrUtcDateTime,
} from '../utils';
import datetimeUtils from '../../../../../utils/datetime';
import html2canvas from 'html2canvas';
import { getStationsMeasures } from '../../../../../services/api/endpoints/stations/station-measures';
import {
    getManageCitiesByState,
    getV2ManageStationByInstitutions,
} from '../../../../../services/api';
import { useAuth } from '../../../../../contexts/auth';
import { useWeatherVariablesUnit } from '../../../../../hooks/use-weather-variables-unit';

const limit = 50;
export function useDownloadPageStates() {
    const [city, setCity] = useState(null);
    const [institution, setInstitution] = useState(null);
    const [filteredStations, setFilteredStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState([]);

    const [variablesOperations, setVariablesOperations] = useState([]);
    const [paginateParams, setPaginateParams] = useState({});

    const [datetimeStart, setDatetimeStart] = useState(null);
    const maximumDatetime = Date.now();
    const [datetimeEnd, setDatetimeEnd] = useState(maximumDatetime);
    const [minimumDatetime, setMinimumDatetime] = useState(null);

    const [frequency, setFrequency] = useState(0);
    const [localHour, setLocalHour] = useState(true);

    const [responseData, setResponseData] = useState(null);
    const [selectedStationsObject, setSelectedStationsObject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [canSearch, setCanSearch] = useState(false);
    const [canDownload, setCanDownload] = useState(false);
    const chartRef = useRef(null);
    const [cities, setCities] = useState([]);

    const apiData = useApiData();
    const { settingsSet } = useAuth();
    const { STATION_INSTITUTION_ALIASES = [] } = { ...settingsSet };
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

    const {
        manageStationInstitutions: {
            fetch: fetchInstitutions,
            value: institutions,
        },
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
        manageStations: { identifiesDelayedStations },
        systemSettings: { fetch: fetchSystemSettings, value: systemSettings },
    } = apiData;

    const filteredInstitutions = useMemo(() => {
        if (!institutions) {
            return [];
        }
        return institutions.filter(({ alias }) =>
            STATION_INSTITUTION_ALIASES.includes(alias)
        );
    }, [STATION_INSTITUTION_ALIASES, institutions]);

    useTerritoriesState(apiData);
    const { getUnitLabel } = useWeatherVariablesUnit(weatherVariables);

    useEffect(() => {
        fetchInstitutions();
    }, [fetchInstitutions]);

    const fetchCitiesByStateId = useCallback(async () => {
        if (city && city.state_id !== null) {
            const response = await getManageCitiesByState(city.state_id);
            setCities(response);
        }
    }, [city]);

    useEffect(() => {
        if (institution) {
            fetchCitiesByStateId();
        }
    }, [fetchCitiesByStateId, institution]);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        fetchSystemSettings();
    }, [fetchSystemSettings]);

    useEffect(() => {
        if (minimumDatetime || !systemSettings) {
            return;
        }
        const { 
            STATION_MEASUREMENTS_MAXIMUM_STORAGE_PERIOD: maxPeriod = 0 
        } = systemSettings;
        const minDatetime = Date.now() - maxPeriod;
        const initialDate = new Date().setDate(new Date().getDate() - 1);
        setMinimumDatetime(minDatetime);
        setDatetimeStart(initialDate);
    }, [systemSettings, minimumDatetime]);

    function checkCanSearchOrCanDownload(
        selectedStation,
        datetimeStart,
        datetimeEnd,
        variablesOperations,
        frequency,
        responseData = null
    ) {
        const hasSelectedStation = selectedStation.length > 0;
        const hasDatetimeStart = Boolean(datetimeStart);
        const hasDatetimeEnd = Boolean(datetimeEnd);
        const hasVariablesOperations = variablesOperations.length > 0;
        const allVariablesHaveOperations = variablesOperations.every(
            (input) => input.variableId !== 'add' && input.operations.length > 0
        );
        const allVariablesValid = variablesOperations.every(
            (input) => input.variableId !== 'add'
        );

        const baseConditions =
            hasSelectedStation &&
            hasVariablesOperations &&
            (frequency !== 0 ? allVariablesHaveOperations : allVariablesValid);

        if (responseData !== null) {
            return baseConditions && responseData?.total > 0;
        }

        return baseConditions && hasDatetimeStart && hasDatetimeEnd;
    }

    useEffect(() => {
        const canSearchMeasures = checkCanSearchOrCanDownload(
            selectedStation,
            datetimeStart,
            datetimeEnd,
            variablesOperations,
            frequency
        );

        setCanSearch(canSearchMeasures);
    }, [
        datetimeEnd,
        datetimeStart,
        frequency,
        selectedStation,
        variablesOperations,
    ]);

    useEffect(() => {
        const canDownload = checkCanSearchOrCanDownload(
            selectedStation,
            null,
            null,
            variablesOperations,
            frequency,
            responseData
        );

        setCanDownload(canDownload);
    }, [frequency, responseData, selectedStation, variablesOperations]);

    function handleSelectCity([state_id, city_id]) {
        setCity({
            state_id: state_id || null,
            city_id: city_id || null,
        });
        setSelectedStation([]);
    }

    const fetchStationsByInstitution = useCallback(
        async (institutionId, filterByCity = true) => {
            setLoading(true);
            const stations = await getV2ManageStationByInstitutions(
                institutionId,
                filterByCity
            );

            let stationsFiltered = stations;

            if (city && city.city_id !== null) {
                stationsFiltered = stationsFiltered.filter(
                    (station) => station.city_id === city.city_id
                );
            }

            if (city && city.state_id !== null) {
                const cityIdsInState = cities.map((city) => city.id);

                stationsFiltered = stationsFiltered.filter((station) =>
                    cityIdsInState.includes(station.city_id)
                );
            }
            const checkDelayedStations = await identifiesDelayedStations(
                stationsFiltered
            );
            setFilteredStations(checkDelayedStations);
            setLoading(false);
        },
        [cities, city, identifiesDelayedStations]
    );

    useEffect(() => {
        if (institution) {
            fetchStationsByInstitution(institution.id);
        }
    }, [institution, fetchStationsByInstitution]);

    const filterSelectedStations = useCallback(() => {
        setSelectedStationsObject(
            filteredStations
                .filter((station) => selectedStation.includes(station.id))
                .reduce((acc, station) => {
                    acc[station.id] = station.name;
                    return acc;
                }, {})
        );
    }, [selectedStation, filteredStations]);

    const updateResponseData = useCallback((paginateParamsData) => {
        setResponseData(paginateParamsData);
    }, []);

    const handleSearch = useCallback(async () => {
        if (!canSearch) return;
        if (responseData) {
            setResponseData(null);
        }
        setLoading(true);
        const searchParams = {
            page: 1,
            limit,
            station_ids: selectedStation,
            datetime_start: new Date(datetimeStart).getTime(),
            datetime_end: new Date(datetimeEnd).getTime(),
            variables: variablesOperations.map((variable) => {
                if (frequency !== 0) {
                    return {
                        variable_id: Number(variable.variableId),
                        operations: variable.operations,
                    };
                }
                return {
                    variable_id: Number(variable.variableId),
                };
            }),
        };
        if (frequency !== 0) searchParams.frequency = frequency * 60 * 1000;
        setPaginateParams(searchParams);
        filterSelectedStations();

        const response = await getStationsMeasures(searchParams);

        setResponseData(response);

        setLoading(false);
    }, [
        canSearch,
        datetimeEnd,
        datetimeStart,
        filterSelectedStations,
        frequency,
        responseData,
        selectedStation,
        variablesOperations,
    ]);

    const getVariableName = useCallback(
        (variableKey) => {
            return weatherVariables.find(
                (variable) => variable.key === variableKey
            ).label;
        },
        [weatherVariables]
    );
    const unitLabelHideList = ['min_datetime', 'max_datetime'];
    const handleGenerateCSV = useCallback(
        (downloadData = null) => {
            if (!responseData) return;

            let generateCsvData = downloadData || responseData;

            const headers = ['Hora da leitura'];
            generateCsvData.data.forEach((station) => {
                const { variables, operations } =
                    getVariablesAndOperations(station);
                variables.forEach((variable) => {
                    operations[variable].forEach((operation) => {
                        const unitLabel = unitLabelHideList.includes(operation)
                            ? ''
                            : `(${getUnitLabel(variable)})`;
                        headers.push(
                            `${
                                selectedStationsObject[station.station_id]
                            } - ${getVariableName(
                                variable
                            )} ${unitLabel} - ${getOperationLabel(operation)}`
                        );
                    });
                });
            });

            const allDatetimes = Array.from(
                new Set(
                    generateCsvData.data.flatMap((station) =>
                        station.results.map((result) => result.datetime)
                    )
                )
            ).sort((a, b) => new Date(a) - new Date(b));

            const rows = allDatetimes.map((datetime) => {
                const row = [formatLocalOrUtcDateTime(datetime, localHour)];
                generateCsvData.data.forEach((station) => {
                    const resultForDatetime =
                        station.results.find(
                            (result) => result.datetime === datetime
                        ) || {};
                    const { variables, operations } =
                        getVariablesAndOperations(station);
                    variables.forEach((variable) => {
                        operations[variable].forEach((operation) => {
                            const value =
                                resultForDatetime[variable]?.[operation];
                            row.push(
                                value !== undefined
                                    ? operation.includes('datetime')
                                        ? datetimeUtils.getInputFormatTimeFromDatetime(
                                              value
                                          )
                                        : value
                                    : '-'
                            );
                        });
                    });
                });
                return row.join(';');
            });

            const csvContent = [headers.join(';'), ...rows].join('\n');

            const csvFilename = `i${datetimeUtils.getFilenameFormatDatetimeFromDatetime(
                datetimeStart
            )}_${datetimeUtils.getFilenameFormatDatetimeFromDatetime(
                datetimeEnd
            )}_Estação.csv`;

            downloadCSV(csvContent, csvFilename);
        },
        [
            datetimeEnd,
            datetimeStart,
            getUnitLabel,
            getVariableName,
            localHour,
            responseData,
            selectedStationsObject,
            unitLabelHideList,
        ]
    );

    const handleDownloadCompleteCSV = useCallback(async () => {
        setIsDownloadingCsv(true);

        const searchParams = { ...paginateParams };
        delete searchParams.page;
        delete searchParams.limit;

        const response = await getStationsMeasures(searchParams);
        handleGenerateCSV(response);

        setIsDownloadingCsv(false);
    }, [handleGenerateCSV, paginateParams]);

    const downloadCSV = (csvContent, filename) => {
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleGeneratePNG = useCallback(async () => {
        if (!chartRef.current) return;

        const canvas = await html2canvas(chartRef.current);

        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `i${datetimeUtils.getFilenameFormatDatetimeFromDatetime(
            datetimeStart
        )}_${datetimeUtils.getFilenameFormatDatetimeFromDatetime(
            datetimeEnd
        )}_Gráfico.png`;
        link.click();
    }, [datetimeEnd, datetimeStart]);

    const stationsOptions = useMemo(() => {
        return (
            filteredStations &&
            filteredStations.map((station) => {
                const suffix = station.inactive
                    ? ' [DESATIVADA]'
                    : station.is_delayed
                    ? ' [ATRASADA]'
                    : '';
                return {
                    value: station.id,
                    label: `${station.name} (${station.code})${suffix}`,
                };
            })
        );
    }, [filteredStations]);
    return {
        city,
        setCity,
        handleSelectCity,
        filteredInstitutions,
        institution,
        setInstitution,
        setSelectedStation,
        filteredStations,
        selectedStation,
        selectedStationsObject,
        stationsOptions,
        frequency,
        setFrequency,
        localHour,
        setLocalHour,
        weatherVariables,
        variablesOperations,
        setVariablesOperations,
        datetimeStart,
        setDatetimeStart,
        datetimeEnd,
        setDatetimeEnd,
        minimumDatetime,
        maximumDatetime,
        handleSearch,
        canSearch,
        canDownload,
        handleGenerateCSV,
        handleGeneratePNG,
        responseData,
        loading,
        chartRef,
        updateResponseData,
        paginateParams,
        handleDownloadCompleteCSV,
        isDownloadingCsv,
    };
}
