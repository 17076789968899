import { useMemo } from 'react';

export const usePaginationStates = ({
    totalPages,
    currentPage,
    maxPagesToShow = 4,
}) => {
    const pages = useMemo(() => {
        const pageList = [];

        pageList.push(1);

        if (totalPages <= maxPagesToShow * 2) {
            for (let i = 2; i <= totalPages; i++) {
                pageList.push(i);
            }
        } else if (currentPage <= maxPagesToShow) {
            for (let i = 2; i <= maxPagesToShow; i++) {
                pageList.push(i);
            }
            pageList.push('...');
            for (
                let i = totalPages - maxPagesToShow + 1;
                i <= totalPages;
                i++
            ) {
                pageList.push(i);
            }
        } else if (currentPage >= totalPages - (maxPagesToShow - 1)) {
            for (let i = 2; i <= maxPagesToShow; i++) {
                pageList.push(i);
            }
            pageList.push('...');
            for (
                let i = totalPages - (maxPagesToShow - 1);
                i <= totalPages;
                i++
            ) {
                pageList.push(i);
            }
        } else {
            pageList.push('...');
            const startPage = currentPage;
            const endPage = Math.min(
                totalPages - 1,
                currentPage + maxPagesToShow - 1
            );

            for (let i = startPage; i <= endPage; i++) {
                pageList.push(i);
            }

            pageList.push('...');
            pageList.push(totalPages);
        }

        return pageList;
    }, [totalPages, currentPage, maxPagesToShow]);

    return { pages };
};
