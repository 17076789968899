import React from 'react';
import { useMeasuresChartStates } from './states';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    LineElement,
    PointElement,
    Title,
    Legend,
    Tooltip,
    LineController,
} from 'chart.js';

import './styles.css';
import { drawDirectionArrowData } from '../../../../../utils/chart';

ChartJS.register(
    LinearScale,
    CategoryScale,
    LineElement,
    PointElement,
    LineController,
    Title,
    Legend,
    Tooltip
);

function MeasuresChart({
    measures,
    weatherVariables,
    selectedStations,
    isLocalTime,
}) {
    const { chartData } = useMeasuresChartStates(
        measures,
        weatherVariables,
        selectedStations,
        isLocalTime
    );

    const transformedData = {
        labels: chartData.labels,
        datasets: chartData.datasets.map((dataset) => {
            if (dataset.variableKey === 'wind_direction') {
                return {
                    ...dataset,
                    showLine: false,
                    pointRadius: 0,
                    yAxisID: 'windDirectionAxis',
                };
            }
            return dataset;
        }),
    };

    return (
        <div className="download-station-measures-chart-container">
            {chartData && (
                <div className="download-station-measures-chart-content">
                    <Chart
                        type="line"
                        data={transformedData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            elements: {
                                point: {
                                    radius: 3,
                                },
                            },
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    onClick(e, legendItem) {
                                        const index = legendItem.datasetIndex;
                                        const ci = this.chart;
                                        const meta = ci.getDatasetMeta(index);

                                        if (index !== 0) {
                                            const hidden =
                                                meta.hidden === null
                                                    ? true
                                                    : meta.hidden;
                                            meta.hidden = !hidden;
                                        } else {
                                            meta.hidden = !meta.hidden;
                                        }
                                        ci.update();
                                    },
                                    onHover(event) {
                                        event.native.target.style.cursor =
                                            'pointer';
                                    },
                                    onLeave(event) {
                                        event.native.target.style.cursor =
                                            'default';
                                    },
                                },
                                tooltip: {
                                    displayColors: false,
                                    titleFont: {
                                        size: 16,
                                    },
                                    bodyFont: {
                                        size: 14,
                                    },
                                    padding: 10,
                                },
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        autoSkip: true,
                                        align: 'center',
                                        maxRotation: 0,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                },
                                windDirectionAxis: {
                                    display: false,
                                },
                            },
                            animation: {
                                duration: 500,
                            },
                            hover: {
                                animationDuration: 200,
                            },
                            responsiveAnimationDuration: 500,
                        }}
                        plugins={[drawDirectionArrowData]}
                        height={`260px`}
                    />
                </div>
            )}
        </div>
    );
}

export default MeasuresChart;
