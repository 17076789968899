const MINUTE_IN_MS = 60000;

export default {
    // 1720028112000 -> 03/07/2024 às 14:35
    getFormatDatetimeFromDatetime(datetimeInMs, utc=false, separator='às') {
        const date = new Date(Number(datetimeInMs));
        const day = String(utc ? date.getUTCDate() : date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(utc? date.getUTCHours() : date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} ${separator} ${hours}:${minutes}`;
    },
    // 1720028112000 -> 20240703T1435
    getFilenameFormatDatetimeFromDatetime(datetimeInMs, utc=false) {
        const date = this.getInputFormatDateFromDatetime(datetimeInMs, utc);
        const time = this.getInputFormatTimeFromDatetime(datetimeInMs, utc);
        return `${date.replaceAll('-', '')}T${time.replace(':', '').substring(0, 4)}`;
    },
    // 1720028112000 -> 2024-07-03
    getInputFormatDateFromDatetime(datetimeInMs, utc=false) {
        const dateObject = new Date(Number(datetimeInMs));
        const utcStr = utc ? 'UTC' : '';
        const getYear = `get${utcStr}FullYear`;
        const getMonth = `get${utcStr}Month`;
        const getDate = `get${utcStr}Date`;
        const year = dateObject[getYear]();
        const month = `${dateObject[getMonth]()+1}`.padStart(2, '0');
        const day = `${dateObject[getDate]()}`.padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
    // 1720028112000 -> 14:35:12
    getInputFormatTimeFromDatetime(datetimeInMs, utc=false) {
        const dateObject = new Date(Number(datetimeInMs));
        const utcStr = utc ? 'UTC' : '';
        return [
            `get${utcStr}Hours`,
            `get${utcStr}Minutes`,
            `get${utcStr}Seconds`,
        ].map(dateFn => `${dateObject[dateFn]()}`.padStart(2, '0'))
            .join(':');
    },
    // 1720028112000 -> 2024-07-03T14:35
    getInputFormatDatetimeFromDatetime(datetimeInMs, utc=false) {
        const date = this.getInputFormatDateFromDatetime(datetimeInMs, utc);
        const time = this.getInputFormatTimeFromDatetime(datetimeInMs, utc).substring(0, 5);
        return `${date}T${time}`;
    },
    addNDays(datetimeInMs, nDays) {
        return datetimeInMs + (nDays * 24 * 60 * MINUTE_IN_MS);
    },
    getUTCZeroHourDatetimeFromInputFormatDate(dateValue) {
        return (new Date(`${dateValue}T00:00:00Z`)).getTime();
    },
    getZeroHourDatetimeFromInputFormatDate(dateValue) {
        return (new Date(`${dateValue}T00:00:00`)).getTime();
    },
    getTodayZeroHourDatetime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = `${now.getMonth()+1}`.padStart(2, '0');
        const day = `${now.getDate()}`.padStart(2, '0');
        const dateValue = `${year}-${month}-${day}`;
        return (new Date(`${dateValue}T00:00:00`)).getTime();
    },
    minutesToMs(minutes) {
        if (!minutes) return ;
        return Number(minutes)*MINUTE_IN_MS;
    },
    msToMinutes(milliseconds) {
        if (!milliseconds) return ;
        return Math.round(Number(milliseconds)/MINUTE_IN_MS);
    },
    msToHours(milliseconds) {
        if (!milliseconds) return ;
        return Math.round(this.msToMinutes(milliseconds)/60);
    },
    getDayOfTheWeek(datetime) {
        return ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'][(new Date(datetime)).getDay()];
    },

    showOnlyHours(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit' });
    },

};
