export function drawArrow(ctx, x, y, degrees) {
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate((degrees + 90) * (Math.PI / 180));
    ctx.fillRect(-9, -1.5, 14, 3);
    ctx.beginPath();
    ctx.moveTo(5, 0);
    ctx.lineTo(2, -5);
    ctx.lineTo(12, 0);
    ctx.lineTo(2, 5);
    ctx.lineTo(5, 0);
    ctx.fill();
    ctx.restore();
}

export const drawDirectionArrowData = {
    id: 'directionArrowData',
    beforeDraw(chart, args, options) {
        const {
            ctx,
            chartArea: { left, top, width },
        } = chart;
        const windDirectionIndex = chart.data.datasets.findIndex(
            ({ variableKey }) => variableKey === 'wind_direction'
        );
        if (windDirectionIndex === -1) {
            return;
        }
        const { data: directions } = chart.data.datasets[windDirectionIndex];
        const windDirectionMeta = chart.getDatasetMeta(windDirectionIndex);
        if (!windDirectionMeta.visible || !directions) {
            return;
        }
        const centerY = top + 10;
        const arrowSize = 20;
        const maxArrows = Math.floor(width / arrowSize);
        const totalArrows = Math.min(maxArrows, directions.length);
        const dataSkip = Math.floor(directions.length / totalArrows);
        const gapArrows =
            totalArrows < maxArrows && totalArrows > 1
                ? (maxArrows - totalArrows) / (totalArrows - 1)
                : 0;

        for (let i = 0; i < totalArrows; i++) {
            const centerX = left + i * arrowSize * (gapArrows + 1);
            const index = dataSkip * i;
            const degree = Number(directions[index]);
            if (!isNaN(degree)) {
                drawArrow(ctx, centerX, centerY, degree);
            }
        }
    },
};
