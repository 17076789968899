import React from 'react';
import { toast } from 'react-toastify';
import { AiFillCopy } from 'react-icons/ai';

import { Link } from 'react-router-dom';

import EntityBox from '../auth/entity-box';
import EntityRolesHeader from '../auth/entity-roles-header';
import EntityRoles from '../auth/entity-roles';

import { CustomerSelectionInput, CheckboxFilledInput } from '../../common/custom-inputs';
import { useUserBoxState } from './states';
import ActionIconBtn from '../../common/btn/action-icon-btn';

import './styles.css';

function UserBox({ user, botUser, rolesList }) {

    const {
        creating,
        fetchUserRoles,
        handleChangeBotUser,
        handleChangeUser,
        handleChangeUserRoles,
        inviteCode,
        inviteUser,
        resetBotMessage,
        saveAll,
        shouldSave,
        title,
        titleIconUser,
        titleIconWhatsapp,
        updatedBotUser,
        updatedUser,
        updatedUserRoles,
    } = useUserBoxState({ user, botUser });

    function copyInviteCode() {
        const inviteUrl = `${window.location.origin}/register?code=${inviteCode}`;
        navigator.clipboard.writeText(inviteUrl);
        toast(`Link copiado para a área de transferência. `, {
            type: 'success',
        });
    }

    const formatPhoneNumber = () => {
        const rawPhoneNumber = updatedBotUser?.phone_number;
        let number = '';
        if(rawPhoneNumber){
            if (rawPhoneNumber.length <= 2) {
                number = `+${rawPhoneNumber}`;
            } else if (rawPhoneNumber.length <= 4) {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2)}`;
            } else if (rawPhoneNumber.length <= 9) {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2, 4)}) ${rawPhoneNumber.slice(4)}`;
            } else {
                number =`+${rawPhoneNumber.slice(0, 2)} (${rawPhoneNumber.slice(2, 4)}) ${rawPhoneNumber.slice(4, 9)}-${rawPhoneNumber.slice(9)}`;
            }
        }
        return number;
    };

    const postBackendPhoneNumber = (event) => {
        const number = event.target.value
        // Remover caracteres não numéricos do input
        const rawPhoneNumber = number.replace(/\D/g, '');
        // Remover o caractere '+' do início, se existir
        return rawPhoneNumber.startsWith('+') ? rawPhoneNumber.slice(1) : rawPhoneNumber;
    };

    return (
        <EntityBox
            creating={creating}
            title={title}
            titleIconUser={titleIconUser}
            titleIconWhatsapp={titleIconWhatsapp}
            onOpen={fetchUserRoles}
        >
            <div className="user-box-form">
                <div className="form-group">
                    <label htmlFor={`user-name-${updatedUser.id}`}>
                        Nome e Sobrenome
                    </label>
                    <input
                        id={`user-name-${user.tempId}`}
                        value={(updatedUser.name || '')}
                        placeholder="Nome (opcional)"
                        onChange={(event) => {
                            handleChangeUser({
                                name: event.target.value,
                            })
                        }}
                        maxLength={40}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor={`user-email-${updatedUser.id || user.tempId}`}>
                        E-mail
                    </label>
                    <input
                        id={`user-email-${updatedUser.id || user.tempId}`}
                        value={updatedUser.email || ''}
                        placeholder="E-mail"
                        onChange={(event) => {
                            handleChangeUser({
                                email: event.target.value,
                            })
                        }}
                        maxLength={70}
                    />
                </div>

                <div className="form-group checkbox-input">
                    <CheckboxFilledInput 
                        id={`user-inactive-${user.tempId}`}
                        value={!updatedUser.inactive}
                        onChange={(event) => {
                            handleChangeUser({
                                inactive: !event.target.checked,
                            })
                        }}
                    />
                    <label htmlFor={`user-inactive-${user.tempId}`}>Ativo</label>
                </div>
            </div>

            <h5>Cliente</h5>
            <CustomerSelectionInput
                defaultValue={updatedUser.customer_id}
                setValue={(value) => {
                    handleChangeUser({
                        customer_id: Number(value),
                    })
                }}
                isNullable={false}
            />

            
            {!updatedUser.password_set && updatedUser.email && (
                <Link
                    to="#"
                    className={`invite-user-btn`}
                    onClick={inviteUser}
                >
                    Convidar
                </Link>
            )}

            <EntityBox
                alwaysOpen={true}
                title="Notificações"
            >
                <EntityRolesHeader
                    showSaveBtn={false}
                />

                <div className="telegram-user-container">
                    <div className="form-group">
                    <label htmlFor={`bot-whtasapp-user-phoneNumer-${user.id}`}>
                        Número do Whatsapp
                    </label>
                        <input
                            type="text"
                            id={`bot-whtasapp-user-phoneNumber-${user.tempId}`}
                            defaultValue={formatPhoneNumber()}
                            onChange={(event) => {
                                handleChangeBotUser({
                                    phone_number: postBackendPhoneNumber(event)
                                }, 'phone_number')
                            }
                            }
                            maxLength={19}
                            placeholder="+00 (00) 00000-0000"
                        />
                    </div>
                </div>

                {botUser?.phone_number && (
                    <div className="form-group checkbox-input user-box-form-notifications">
                        <div>
                            <CheckboxFilledInput 
                                id={`bot-whatsapp-user-active-${user.tempId}`}
                                value={!botUser.inactive}
                                onChange={(event) =>
                                    handleChangeBotUser({
                                        inactive: !event.target.checked,
                                    }, 'inactive')
                                }
                            />
                            <label htmlFor={`bot-whatsapp-user-active-${user.tempId}`}>Ativo</label>
                        </div>

                        <div>
                            <CheckboxFilledInput
                                id={`bot-whatsapp-user-subscribed-${user.tempId}`}
                                value={botUser.subscribed}
                                onChange={(event) =>
                                    handleChangeBotUser({
                                        subscribed: event.target.checked,
                                    }, 'subscribed')
                                }
                            />
                            <label htmlFor={`bot-whatsapp-user-subscribed-${user.tempId}`}>Inscrito</label>
                        </div>

                        <ActionIconBtn
                            disabled={updatedBotUser.subscribed}
                            action={resetBotMessage}
                            label="Reiniciar Conversa"
                            className="main-btn"
                        />
                    </div>
                )}
            </EntityBox>


            <EntityBox
                alwaysOpen={true}
                title="Papéis"
            >
                <EntityRolesHeader
                    showSaveBtn={false}
                    feedback={'* Papéis associados somente ao usuário'}
                />

                <EntityRoles
                    entityRoles={updatedUserRoles}
                    setEntityRoles={(value) => handleChangeUserRoles(value)}
                    rolesList={rolesList}
                />
            </EntityBox>

            <div className='info-save user-box-form-save-btn'>
                {!updatedUser.password_set && inviteCode && (
                    <Link
                        to="#"
                        className={`link-copy-btn`}
                        onClick={copyInviteCode}
                    >
                        <AiFillCopy /> Link de Registro
                    </Link>
                )}
                <Link
                    to="#"
                    className={`save-btn ${shouldSave ? '' : 'disabled'}`}
                    onClick={saveAll}
                >
                    Salvar
                </Link>
            </div>

        </EntityBox>
    );
}

export default UserBox;
