import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { useMeasuresChartStates } from './states';

import './styles.css';
import { drawArrow } from '../../../../utils/chart';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    Title,
    Legend,
    Tooltip,
);

function MeasuresChart({ measures, weatherVariables }) {
    const {
        chartData,
    } = useMeasuresChartStates(measures, weatherVariables);

    const directionArrowData = {
        id: 'directionArrowData',

        beforeDraw(chart, args, options) {
            const {
                ctx,
                chartArea: { left, top, width },
            } = chart;
            
            const windSpeedIndex = chart.data.datasets.findIndex(({ variableKey }) => variableKey === 'wind_speed');
            if (windSpeedIndex === -1) {
                return;
            }
            
            const { directions } = chart.data.datasets[windSpeedIndex];
            const windSpeedMeta = chart.getDatasetMeta(windSpeedIndex);
            if (!windSpeedMeta.visible || !directions) {
                return;
            }
            const centerY = top;
            const arrowSize = 20;
            const maxArrows = Math.floor(width/arrowSize);
            const totalArrows = Math.min(maxArrows, directions.data.length);
            const dataSkip = Math.floor(directions.data.length / totalArrows);
            const gapArrows = (totalArrows < maxArrows && totalArrows > 1)
                ? (maxArrows-totalArrows)/(totalArrows-1)
                : 0;

            for (let i = 0; i<totalArrows; i++) {
                const centerX = left + i*arrowSize*(gapArrows+1);
                const index = dataSkip*i;
                const degree = Number(directions.data[index]);
                if (!isNaN(degree)) {
                    drawArrow(ctx, centerX, centerY, degree);
                }
            }
        }
    };

    return (
        <div className="stations-weather-measures-chart-container">
            {chartData && (
                <Chart
                    type="line"
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: {
                            point:{
                                radius: 0,
                            },
                        },
                        plugins: {
                            legend: {
                                position: 'bottom',
                                onClick(e, legendItem) {
                                    const index = legendItem.datasetIndex;
                                    const ci = this.chart;
                                    
                                    ci.data.datasets.forEach(function(e, i) {
                                        const meta = ci.getDatasetMeta(i);

                                        if (i === index) {
                                            meta.hidden = false;
                                        } else {
                                            meta.hidden = true;
                                        }
                                    });

                                    ci.update();
                                },
                                onHover(event) {
                                    event.native.target.style.cursor = 'pointer';
                                },
                                onLeave(event) {
                                    event.native.target.style.cursor = 'default';
                                },
                            },
                            tooltip: {
                                displayColors: false,
                                titleFontSize: 16,
                                bodyFontSize: 14,
                                xPadding: 10,
                                yPadding: 10,
                                // callbacks: {
                                //     label: (tooltipItem) => {
                                //         const annotation = tooltipItem.dataset.annotations[tooltipItem.dataIndex];
                                //         return [
                                //             `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${annotation ? `(${annotation})` : ''}`,
                                //         ];
                                //     },
                                // },
                            },
                        },
                        scales: {
                            xAxis: {
                                ticks: {
                                    autoSkip: true,
                                    align: 'center',
                                    maxRotation: 0,
                                },
                                grid: {
                                    display: false,
                                },
                            },
                            // yAxis: {
                            //     max,
                            //     min: 0,
                            //     ticks: {
                            //         stepSize: daysFrequency ? 10 : 1,
                            //         maxTicksLimit: daysFrequency ? 6 : 11,
                            //     },
                            // },
                        },
                    }}
                    plugins={[directionArrowData]}
                    height={'250px'}
                    className="stations-weather-measures-chart"
                />
            )}
        </div>
    );
}

export default MeasuresChart;
