import {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

import {
    getStationsMeasures,
    getStationHighestPrecipitations,
    postStationHighestPrecipitations,
} from '../../../../services/api';
import { useApiData } from '../../../../contexts/api-data';
import datetimeUtils from '../../../../utils/datetime';
import downloadFile from '../../../../services/download-file';

const ALL_STATIONS_VALUE = 'ALL_STATIONS_VALUE';

function getPeriod(key) {
    const timePeriodStr = key.replace('precipitation', '');
    if (timePeriodStr.includes('min')) {
        return Number(timePeriodStr.replace('min', ''));
    }
    if (timePeriodStr.includes('h')) {
        return Number(timePeriodStr.replace('h', ''))*60;
    }
    if (timePeriodStr.includes('m')) {
        return Number(timePeriodStr.replace('m', ''))*60*24*30;
    }
    return 0;
}

function getFrequencyLabel(key) {
    const timePeriodStr = key.replace('precipitation', '');
    if (timePeriodStr.includes('min')) {
        return timePeriodStr.replace('min', '') + ' minutos';
    }
    if (timePeriodStr.includes('h')) {
        return timePeriodStr.replace('h', '') + ' horas';
    }
    if (timePeriodStr.includes('m')) {
        return timePeriodStr.replace('m', '') + ' meses';
    }
    return '';
}

export function useStationHighestPrecipitationsStates() {
    const {
        manageStations: {
            fetchByInstitution,
            value: allStations,
        },
        manageStationInstitutions: {
            getIdByAlias: getIdByStationInstitutionAlias,
        },
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
    } = useApiData();
    
    const stations = allStations?.filter(({ inactive }) => !inactive);
    const [stationId, setStationId] = useState(ALL_STATIONS_VALUE);
    const [variableId, setVariableId] = useState(null);
    const precipitationVariables = useMemo(
        () => weatherVariables
            ?.filter(({ key }) => key.startsWith('precipitation')
                && !key.startsWith('precipitationtx')
                && key !== 'precipitation'
            )
            .sort((a, b) => getPeriod(a.key) - getPeriod(b.key)),
        [weatherVariables],
    );
    const variablesOptions = useMemo(() => {
        return precipitationVariables?.map(({ id, key }) => ({
            value: id,
            label: getFrequencyLabel(key),
        }));
    }, [precipitationVariables]);
    const frequency = useMemo(() => {
        const variable = variablesOptions?.find(({ value }) => value === variableId);
        if (!variable) {
            return '';
        }
        return variable.label;
    }, [variablesOptions, variableId]);
    const [precipitations, setPrecipitations] = useState(null);
    const [searchingPrecipitations, setSearchingPrecipitations] = useState(false);
    const [highestPrecipitations, setHighestPrecipitations] = useState(null);
    const initialHighestPrecipitations = useRef();
    const previousPage = useRef(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pages = useMemo(() => {
        if (!totalPages) {
            return null;
        }
        if (totalPages <= 10) {
            return [...(new Array(totalPages)).keys()].map(page => page+1);
        }
        const topLimit = totalPages-3;
        const bottomLimit = 4;
        const isVeryMiddlePage = (currentPage>bottomLimit && currentPage<topLimit);
        const firstSummary = (isVeryMiddlePage || currentPage===topLimit) ? [-1] : [];
        const middlePages = (currentPage>=bottomLimit && currentPage<=topLimit)
            ? [...(new Array(5)).keys()].map(index => currentPage-2+index)
            : [...(new Array(3)).keys()].map(index => index+2)
                .concat(-2)
                .concat([...(new Array(3)).keys()].map(index => totalPages-index-1).reverse());
        const lastSummary = (isVeryMiddlePage || currentPage===4) ? [-3] : [];
        return [
            1,
            ...firstSummary,
            ...middlePages,
            ...lastSummary,
            totalPages,
        ];
    }, [currentPage, totalPages]);

    const institutionId = useMemo(() => {
        return getIdByStationInstitutionAlias('alertario');
    }, [getIdByStationInstitutionAlias]);

    function copyHighestPrecipitations(value) {
        return JSON.parse(JSON.stringify(value));
    }

    useEffect(() => {
        if (highestPrecipitations) {
            return;
        }
        (async function() {
            const response = await getStationHighestPrecipitations() || {};
            const { precipitations = {} } = response;
            initialHighestPrecipitations.current = copyHighestPrecipitations(precipitations);
            setHighestPrecipitations(precipitations);
        })();
    }, [highestPrecipitations]);

    useEffect(() => {
        if (stations || !institutionId) return;
        fetchByInstitution(institutionId);
    }, [fetchByInstitution, institutionId, stations]);

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    useEffect(() => {
        if (!precipitationVariables || variableId) {
            return;
        }
        const initialVariable = precipitationVariables.find(({ key }) => key === 'precipitation1h');
        setVariableId(initialVariable?.id);
    }, [precipitationVariables, variableId]);

    const stationsOptions = useMemo(
        () => stations ? 
            [{
                value: ALL_STATIONS_VALUE,
                label: 'Todas',
            }].concat(stations
                ?.filter(({ inactive }) => !inactive)
                ?.sort((a, b) => Number(a.code) - Number(b.code))
                ?.map(({ id, name, code }) => ({
                    value: id,
                    label: `${code} - ${name}`,
                })))
            : null,
        [stations],
    );

    const searchPrecipitations = useCallback(async function (resetHighestPrecipitations=true) {
        if (!variableId) {
            return;
        }
        setSearchingPrecipitations(true);
        if (resetHighestPrecipitations) {
            const newHighestPrecipitations = copyHighestPrecipitations(initialHighestPrecipitations.current);
            setHighestPrecipitations(newHighestPrecipitations);
        }
        const station_ids = stationId === ALL_STATIONS_VALUE
            ? stations.filter(({ inactive }) => !inactive).map(({ id }) => id)
            : [stationId];
        const newPrecipitations = await getStationsMeasures({
            station_ids,
            page: currentPage,
            limit: 100,
            variables: [{ variable_id: variableId }],
            sort_by: 'value',
            sort_order: 'desc',
        });
        const variable = weatherVariables.find(({ id }) => id === variableId);
        const allNewPrecipitations = newPrecipitations.data.reduce(
            (allPrecipitations, { station_id, results }) => {
                return allPrecipitations.concat(results.map(data => {
                    const { datetime } = data;
                    return {
                        datetime,
                        variable_id: variableId,
                        station_id,
                        stationName: stations.find(({ id }) => id === Number(station_id))?.name,
                        datetimeStr: datetimeUtils.getFormatDatetimeFromDatetime(datetime, false, '-'),
                        value: Number(data[variable.key].abs),
                    };
                }));
            },
            [],
        );
        setPrecipitations(allNewPrecipitations.sort((b, a) => new Date(a.value) - new Date(b.value)));
        setTotalPages(newPrecipitations.pages);
        setSearchingPrecipitations(false);
    }, [stationId, stations, variableId, currentPage, weatherVariables]);

    useEffect(() => {
        if (!stations || !variableId || precipitations || !weatherVariables) {
            return;
        }
        searchPrecipitations(false);
    }, [variableId, precipitations, stations, weatherVariables, searchPrecipitations]);

    useEffect(() => {
        if (currentPage === previousPage.current) {
            return;
        }
        previousPage.current = currentPage;
        setPrecipitations(null);
    }, [currentPage]);

    function togglePrecipitation(precipitation) {
        const {
            variable_id,
            datetime,
            station_id,
        } = precipitation;
        const oldValue = highestPrecipitations[variable_id] || [];
        highestPrecipitations[variable_id] = oldValue.find(p => p.datetime === datetime && p.station_id === station_id)
            ? oldValue.filter(p => !(p.datetime === datetime && p.station_id === station_id))
            : oldValue.concat(precipitation);
        setHighestPrecipitations({ ...highestPrecipitations });
    }
    
    function shouldSave() {
        return JSON.stringify(highestPrecipitations)
            !== JSON.stringify(initialHighestPrecipitations.current);
    }

    async function save() {
        if (!shouldSave()) {
            return;
        }
        await postStationHighestPrecipitations(highestPrecipitations);
        initialHighestPrecipitations.current = copyHighestPrecipitations(highestPrecipitations);
        setHighestPrecipitations({ ...highestPrecipitations });
    }

    function download() {
        if (searchingPrecipitations) {
            return;
        }
        const rows = (highestPrecipitations[variableId] || []).map(({
            stationName,
            datetimeStr,
            value,
        }) => {
            return [
                stationName,
                datetimeStr,
                value,
            ].join(';');
        });

        const fileName = `maiores_chuvas_${frequency.replace(' ', '_')}_${Date.now()}.csv`;
        const fileContent = [
            `Estação;Hora da Leitura;Acumulado em ${frequency}`,
            ...rows,
        ].join('\n');
        const data = new Blob([fileContent], {
            type: 'data:text/json;charset=utf-8',
        });

        downloadFile(data,fileName);
    }
    
    return {
        currentPage,
        download,
        frequency,
        highestPrecipitations,
        pages,
        precipitations,
        save,
        searchPrecipitations,
        searchingPrecipitations,
        setCurrentPage,
        setStationId,
        setVariableId,
        shouldSave,
        stationId,
        stationsOptions,
        togglePrecipitation,
        totalPages,
        variableId,
        variablesOptions,
    };
};
