
import React, { useEffect, useMemo } from 'react';

import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { useApiData } from '../../contexts/api-data';
import { useAuth } from '../../contexts/auth';

import PinIcon from '../common/custom-icons/pin-icon';

const createReactIcon = (IconComponent, className, delayed, disabled) => {
    const iconHtml = ReactDOMServer.renderToString(
        <IconComponent
            className={className}
            delayed={delayed}
            disabled={disabled}
        />);
    return L.divIcon({
        className: '',
        html: iconHtml,
        iconAnchor: [15, 25],
        popupAnchor: [0, -30],
    });
};

const darkGrayIcon = createReactIcon(PinIcon, "dark-gray", false, true);
const grayIcon = createReactIcon(PinIcon, "gray", true);
const greenIcon = createReactIcon(PinIcon, "green");
const blueIcon = createReactIcon(PinIcon, "blue");
const yellowIcon = createReactIcon(PinIcon, "yellow");
const orangeIcon = createReactIcon(PinIcon, "orange");
const redIcon = createReactIcon(PinIcon, "red");

const precipitationsToShow = [
    'precipitation5min',
    'precipitation10min',
    'precipitation15min',
    'precipitation1h',
];

function getViewValue(rawValue, unit) {
    if (isNaN(rawValue)) {
        return rawValue;
    }
    // eslint-disable-next-line no-eval
    const value = eval(unit?.formula?.replace('x', rawValue));
    const formattedValue = Math.floor(value*10) / 10;
    return `${formattedValue} ${unit?.label}`;
}

function getPrecipitationAndFactor({ precipitation10min, precipitation15min, precipitation1h }) {
    if (!isNaN(precipitation10min)) {
        return [precipitation10min, 6];
    }
    if (!isNaN(precipitation15min)) {
        return [precipitation15min, 4];
    }
    if (!isNaN(precipitation1h)) {
        return [precipitation1h, 1];
    }
    return [0, 0];
}

function getIcon(measures, is_delayed) {
    if (!measures) {
        return darkGrayIcon;
    }
    if (is_delayed) {
        return grayIcon;
    }
    const [rawPrecipitation, factor] = getPrecipitationAndFactor(measures);
    const precipitation = rawPrecipitation * factor;
    if (precipitation < 0.2) {
        return greenIcon;
    }
    if (precipitation <= 5) {
        return blueIcon;
    }
    if (precipitation <= 25) {
        return yellowIcon;
    }
    if (precipitation <= 50) {
        return orangeIcon;
    }
    return  redIcon;
}

export function useStationsMarkersStates() {
    const {
        manageWeatherVariables: {
            fetch: fetchWeatherVariables,
            value: weatherVariables,
        },
        selectedStationInstitution: stationInstitution,
        stations: rawStations,
    } = useApiData();
    const {
        settingsSet,
    } = useAuth();

    useEffect(() => {
        fetchWeatherVariables();
    }, [fetchWeatherVariables]);

    const {
        VARIABLES_UNITS,
    } = { ...settingsSet };

    const variablesUnits = useMemo(() => {
        if (!weatherVariables) {
            return {};
        }
        const unitsByVariableKey = (VARIABLES_UNITS || []).reduce((result, value) => {
            const [variableId, unitId] = value.split(',').map(Number);
            const variable = weatherVariables.find(({ id }) => id === variableId);
            const unit = variable.type.units.find(({ id }) => id === unitId);
            if (unit) {
                result[variable.key] = unit;
            }
            return result;
        }, {});
        weatherVariables.forEach(({ key, type }) => {
            if (key in unitsByVariableKey) {
                return;
            }
            unitsByVariableKey[key] = type.units.find(({ formula }) => formula.trim() === 'x');
        });
        return unitsByVariableKey;
    }, [VARIABLES_UNITS, weatherVariables]);

    const stations = useMemo(() => {
        if (!stationInstitution || !weatherVariables || !rawStations) {
            return null;
        }
        return rawStations?.filter(({ inactive }) => !inactive)
            ?.map(({
                    name,
                    code,
                    latitude,
                    longitude,
                    is_delayed,
                    time_frequency,
                    measures: lastMeasures
                }) => {
                const [stationName, riverName] = name.split(':');
                const frequency = time_frequency < 60
                    ? `${time_frequency}min`
                    : `${time_frequency/60}h`;
                const datetime = lastMeasures?.datetime;

                if (lastMeasures && lastMeasures.hasOwnProperty('precipitation')) {
                    lastMeasures[`precipitation${frequency}`] = 
                        lastMeasures[`precipitation${frequency}`] || lastMeasures['precipitation'];
                    delete lastMeasures['precipitation'];
                }

                const measures = lastMeasures
                    ? Object.keys(lastMeasures)
                        .filter(key => {
                            if (key === 'datetime' || key.includes('SLIP_STAGE')) {
                                return false;
                            }
                            if (key.includes('precipitation')) {
                                return precipitationsToShow.includes(key);
                            }
                            return true;
                        })
                        .map(key => {
                            const variable = weatherVariables.find(v => v.key === key);
                            let value = getViewValue(lastMeasures[key], variablesUnits[key]);
                            if (key === 'level15m') {
                                const percentage = Math.round(
                                    lastMeasures.level15m / lastMeasures.level_flood_quota * 100
                                );
                                value += ` (${percentage}%)`;
                            }
                            return {
                                key,
                                label: variable?.label,
                                value,
                            };
                        })
                    : null;

                const icon = getIcon(lastMeasures, is_delayed);

                const [date, time] = datetime
                    ? (new Date(Number(datetime))).toLocaleString().replace(',', '').split(' ')
                    : [null, null];

                return {
                    stationName,
                    riverName,
                    code,
                    position: [latitude, longitude],
                    icon,
                    measures,
                    date,
                    time: time?.substring(0, 5),
                };
            });
    }, [
        rawStations,
        stationInstitution,
        weatherVariables,
        variablesUnits
    ]);

    return {
        stations
    };
};
