import React from 'react';
import './styles.css';
import {
    CitySelectionInput,
    DatetimeLocalInput,
    MultipleSelectionInput,
} from '../../../../../components/common/custom-inputs';

import Popup from 'reactjs-popup';
import { OperationsInput } from '../operations-input';
import { StationDataTable } from '../download-table';

import MeasuresChart from '../charts';
import Loading from '../../../../../components/loading';

import { useDownloadPageStates } from './states';
import { FiArrowDownCircle } from 'react-icons/fi';
import ActionIconBtn from '../../../../../components/common/btn/action-icon-btn';
import { FaPlus, FaSearch } from 'react-icons/fa';
import Link from 'react-router-dom/Link';

function DownloadStationMeasures() {
    const {
        city,
        handleSelectCity,
        filteredInstitutions,
        institution,
        setInstitution,
        setSelectedStation,
        selectedStation,
        handleSelectStations,
        frequency,
        setFrequency,
        localHour,
        setLocalHour,
        weatherVariables,
        variablesOperations,
        setVariablesOperations,
        datetimeStart,
        setDatetimeStart,
        datetimeEnd,
        setDatetimeEnd,
        minimumDatetime,
        maximumDatetime,
        handleSearch,
        canDownload,
        canSearch,
        handleGenerateCSV,
        handleGeneratePNG,
        responseData,
        loading,
        chartRef,
        selectedStationsObject,
        stationsOptions,
        updateResponseData,
        paginateParams,
        handleDownloadCompleteCSV,
        isDownloadingCsv,
    } = useDownloadPageStates();

    return (
        <div className="station-measures-download-container">
            <div>
                <h4 className="station-measures-download-filters-header">
                    Estações - Histórico de dados e Estatísticas
                </h4>
                <div className="station-measures-download-filters-area">
                    <div className="station-measures-download-filters-inputs-area">
                        <div className="station-measures-download-filters-form">
                            <div className="form-group">
                                <label htmlFor="institution-id">
                                    <span className="station-measures-download-filters-required">
                                        *
                                    </span>
                                    Instituição:
                                </label>
                                <select
                                    id="institution-id"
                                    value={institution?.id || 0}
                                    onChange={(event) => {
                                        const newInstitutionId = Number(
                                            event.target.value
                                        );
                                        const selectedInstitution =
                                            filteredInstitutions.find(
                                                (inst) =>
                                                    inst.id === newInstitutionId
                                            );
                                        setInstitution(
                                            selectedInstitution || null
                                        );
                                        setSelectedStation([]);
                                    }}
                                >
                                    <option disabled value="0">
                                        Selecione
                                    </option>
                                    {filteredInstitutions?.map(
                                        ({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>

                            <div className="form-group station-measures-download-city-list">
                                <label htmlFor="station-city-id">
                                    Cidades:
                                </label>
                                <CitySelectionInput
                                    defaultCityOption={{
                                        value: '0',
                                        label: 'Todas',
                                    }}
                                    defaultStateOption={{
                                        value: '0',
                                        label: 'Todos',
                                    }}
                                    isStateNullable={true}
                                    defaultValue={[
                                        city?.state_id,
                                        city?.city_id,
                                    ]}
                                    selectCity={handleSelectCity}
                                    inputId="station-city-id"
                                    className=""
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="station-id">
                                    <span className="station-measures-download-filters-required">
                                        *
                                    </span>
                                    Estação:
                                </label>
                                <div className="station-measures-download-filters-stations">
                                    <MultipleSelectionInput
                                        options={stationsOptions || []}
                                        defaultValue={selectedStation}
                                        setValue={setSelectedStation}
                                        showOnlyItemsAmount={
                                            selectedStation.length > 1
                                                ? true
                                                : false
                                        }
                                        className={'download-stations-list'}
                                        inputId="station-id"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="station-measures-download-filters-form ">
                            <div className="form-group">
                                <label htmlFor="datetimeStart">
                                    <span className="station-measures-download-filters-required">
                                        *
                                    </span>
                                    Início:
                                </label>
                                <DatetimeLocalInput
                                    inputId="datetimeStart"
                                    value={datetimeStart}
                                    setValue={setDatetimeStart}
                                    disabled={!minimumDatetime}
                                    min={minimumDatetime}
                                    max={datetimeEnd}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="datetimeEnd">
                                    <span className="station-measures-download-filters-required">
                                        *
                                    </span>
                                    Fim:
                                </label>
                                <DatetimeLocalInput
                                    inputId="datetimeEnd"
                                    value={datetimeEnd}
                                    setValue={setDatetimeEnd}
                                    disabled={!minimumDatetime}
                                    min={datetimeStart}
                                    max={maximumDatetime}
                                />
                            </div>
                            <div className="form-group">
                                <Popup
                                    trigger={
                                        <Link
                                            to="#"
                                            className="station-measures-download-filters-button-trigger"
                                        >
                                            <FaPlus size={12} />
                                            Adicionar variáveis
                                        </Link>
                                    }
                                    position="center center"
                                    onOpen={handleSelectStations}
                                    modal
                                    closeOnDocumentClick={false}
                                    className="station-measures-download-popup"
                                >
                                    {(close) => (
                                        <OperationsInput
                                            variables={weatherVariables}
                                            closePopup={close}
                                            setVariablesOperations={
                                                setVariablesOperations
                                            }
                                            variablesOperations={
                                                variablesOperations
                                            }
                                            frequency={frequency}
                                            setFrequency={setFrequency}
                                        />
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </div>
                    <div className="form-group station-measures-download-form-buttons">
                        <ActionIconBtn
                            action={handleSearch}
                            disabled={!canSearch}
                            label="Pesquisar"
                            icon={FaSearch}
                            className="main-btn"
                        />

                        <ActionIconBtn
                            action={handleDownloadCompleteCSV}
                            disabled={!canDownload}
                            label="Baixar"
                            icon={() =>
                                !isDownloadingCsv ? (
                                    <FiArrowDownCircle />
                                ) : (
                                    <Loading size={20} color="#000" />
                                )
                            }
                            className="main-btn"
                        />
                    </div>
                </div>
            </div>
            <>
                {loading && !responseData ? (
                    <div className="station-measures-download-results-area-loading">
                        <Loading size={32} color="#888" />
                    </div>
                ) : (
                    <>
                        {responseData && responseData?.total > 0 ? (
                            <>
                                <div className="station-measures-download-button-container">
                                    <ActionIconBtn
                                        action={handleGeneratePNG}
                                        label="Gerar PNG"
                                        icon={FiArrowDownCircle}
                                        className="station-measures-download-btn"
                                    />
                                    <div className="station-measures-download-checkbox-area-content">
                                        <input
                                            type="checkbox"
                                            id="local-hour-id"
                                            checked={localHour}
                                            onChange={(event) =>
                                                setLocalHour(
                                                    event.target.checked
                                                )
                                            }
                                        />
                                        <label htmlFor="local-hour-id">
                                            Horário Local
                                        </label>
                                    </div>
                                </div>

                                <div
                                    className="station-measures-download-graph-area"
                                    ref={chartRef}
                                >
                                    <MeasuresChart
                                        measures={responseData}
                                        weatherVariables={weatherVariables}
                                        selectedStations={
                                            selectedStationsObject
                                        }
                                        isLocalTime={localHour}
                                    />
                                </div>

                                <div className="station-measures-download-button-container">
                                    <ActionIconBtn
                                        action={handleGenerateCSV}
                                        label="Baixar CSV"
                                        icon={FiArrowDownCircle}
                                        className="station-measures-download-btn"
                                    />
                                    <div className="station-measures-download-checkbox-area-content">
                                        <input
                                            type="checkbox"
                                            id="local-hour-id"
                                            checked={localHour}
                                            onChange={(event) =>
                                                setLocalHour(
                                                    event.target.checked
                                                )
                                            }
                                        />
                                        <label htmlFor="local-hour-id">
                                            Horário Local
                                        </label>
                                    </div>
                                </div>

                                <div className="station-measures-download-results-area">
                                    <StationDataTable
                                        tableData={responseData}
                                        variables={weatherVariables}
                                        selectedStations={
                                            selectedStationsObject
                                        }
                                        localHour={localHour}
                                        updateResponseData={updateResponseData}
                                        paginateParams={paginateParams}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                {responseData && responseData?.total === 0 && (
                                    <div className="station-measures-download-results-notification">
                                        <div className="no-data">
                                            Nenhum resultado encontrado
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        </div>
    );
}

export default DownloadStationMeasures;
