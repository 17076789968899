import React from 'react';
import './styles.css';
import { getOperationLabel, formatLocalOrUtcDateTime } from '../utils';
import datetimeUtils from '../../../../../utils/datetime';
import { useDownloadTableStates } from './states';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { TablePagination } from '../download-table-pagination';
import Loading from '../../../../../components/loading';

export const StationDataTable = ({
    tableData,
    variables,
    selectedStations,
    localHour,
    updateResponseData,
    paginateParams,
}) => {
    const {
        sortedDatetimes,
        sortOrder,
        toggleSortDirection,
        getVariableName,
        fetchPageData,
        isFetching,
        stationColumns,
        variableColumns,
        operationColumns,
        bodyDataColumns,
    } = useDownloadTableStates({
        variables,
        tableData,
        paginateParams,
        updateResponseData,
    });

    return (
        <div className="download-station-measures-table-measures-wrapper">
            {isFetching ? (
                <div className="download-station-measures-table-loading">
                    <Loading size={32} color="#888" />
                </div>
            ) : (
                <div className="download-station-measures-table-container">
                    <table className="download-station-measures-table-content">
                        <thead>
                            <tr>
                                <th
                                    rowSpan="3"
                                    className="download-station-measures-table-hour"
                                    onClick={toggleSortDirection}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Hora da leitura{' '}
                                    {sortOrder === 'asc' ? (
                                        <FaLongArrowAltUp />
                                    ) : (
                                        <FaLongArrowAltDown />
                                    )}
                                </th>
                                {stationColumns.map(
                                    ({ stationId, colSpan }) => (
                                        <th
                                            key={stationId}
                                            colSpan={colSpan}
                                            className="download-station-measures-table-stations"
                                        >
                                            Estação -{' '}
                                            {selectedStations[stationId]}
                                        </th>
                                    )
                                )}
                            </tr>
                            <tr>
                                {variableColumns.map(
                                    ({
                                        stationId,
                                        variable,
                                        colSpan,
                                        unitLabel,
                                    }) => (
                                        <th
                                            key={`${stationId}-${variable}`}
                                            colSpan={colSpan}
                                            className="download-station-measures-table-variables"
                                            onClick={toggleSortDirection}
                                        >
                                            {`${getVariableName(
                                                variable
                                            )} (${unitLabel})`}
                                            {sortOrder === 'asc' ? (
                                                <FaLongArrowAltUp />
                                            ) : (
                                                <FaLongArrowAltDown />
                                            )}
                                        </th>
                                    )
                                )}
                            </tr>
                            <tr>
                                {operationColumns.map(
                                    ({
                                        stationId,
                                        variable,
                                        operation,
                                        unitLabel,
                                    }) => (
                                        <th
                                            key={`${stationId}-${variable}-${operation}`}
                                            className="download-station-measures-table-operations-cell"
                                        >
                                            {`${getOperationLabel(operation)}`}
                                        </th>
                                    )
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDatetimes.map((datetime, rowIndex) => (
                                <tr key={datetime}>
                                    <td className="download-station-measures-table-hour">
                                        {formatLocalOrUtcDateTime(
                                            datetime,
                                            localHour
                                        )}
                                    </td>
                                    {bodyDataColumns[rowIndex].map(
                                        ({
                                            stationId,
                                            variable,
                                            operation,
                                            value,
                                        }) => (
                                            <td
                                                key={`${stationId}-${datetime}-${variable}-${operation}`}
                                            >
                                                {value !== undefined
                                                    ? operation.includes(
                                                          'datetime'
                                                      )
                                                        ? datetimeUtils.getInputFormatTimeFromDatetime(
                                                              value
                                                          )
                                                        : `${value}`
                                                    : '-'}
                                            </td>
                                        )
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <TablePagination
                        totalPages={tableData.pages}
                        currentPage={tableData.page}
                        fetchPageData={fetchPageData}
                    />
                </div>
            )}
        </div>
    );
};
