import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { usePaginationStates } from './states';
import './styles.css';

export const TablePagination = ({
    totalPages,
    currentPage,
    fetchPageData,
    maxPagesToShow = 4,
}) => {
    const { pages } = usePaginationStates({
        totalPages,
        currentPage,
        maxPagesToShow,
    });

    return (
        <div className="download-station-measures-table-pagination-container">
            <span>Página</span>
            {pages.map((page, index) =>
                page === '...' ? (
                    <span
                        key={index}
                        className="download-station-measures-table-separator"
                    >
                        ...
                    </span>
                ) : (
                    <span
                        key={page}
                        onClick={() => fetchPageData(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </span>
                )
            )}

            <span
                key="prev"
                onClick={() =>
                    currentPage > 1 && fetchPageData(currentPage - 1)
                }
                className="download-station-measures-table-pagination-arrow"
            >
                <FaChevronLeft />
            </span>

            <span
                key="next"
                onClick={() =>
                    currentPage < totalPages && fetchPageData(currentPage + 1)
                }
                className="download-station-measures-table-pagination-arrow"
            >
                <FaChevronRight />
            </span>
        </div>
    );
};
