import { useCallback, useState } from 'react';

import { getManageStates } from '../../../../services/api';

export function useManageStatesState() {
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async function() {
        if (loading || value.length) {
            return;
        }
        setLoading(true);
        const states = await getManageStates();
        const sortedStates = states.sort((a, b) => a.uf.localeCompare(b.uf));
        setValue(sortedStates);
        setLoading(false);
    }, [loading, value]);

    return {
        fetch,
        value,
    };
};
