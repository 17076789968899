import axios from 'axios';
import { toast } from 'react-toastify';
import socketio from 'socket.io-client';

import * as sockets from './sockets';
import { mockMirage } from './mocks/mirage-handlers';

const baseURL = process.env.REACT_APP_BASE_API_URL;

const api = axios.create({
    baseURL,
});

export function connectSocket(access_token) {
    const socket = socketio(baseURL, {
        query: {
            access_token,
        },
    });

    socket.on('connected', sockets.onConnected);

    return socket;
};

function getResponseLogData(response) {
    const {
        config: {
            method: originalMethod,
            url: originalUrl,
        },
        data: {
            errors,
        },
        status,
    } = response;
    const method = originalMethod.toUpperCase();
    const url = originalUrl.replace('/manage', '');

    return {
        method,
        url,
        status,
        errors,
    };
}

api.interceptors.request.use((request) => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user'));
    if (loggedUser && loggedUser.authorization) {
        request.headers.common.Authorization = loggedUser.authorization.access_token;
    }
    return request;
});

api.interceptors.response.use((response) => {
    const {
        method,
        url,
    } = getResponseLogData(response);
    const customGetWithBodyData = url === '/stations/measures' && method === 'POST';

    if (method !== 'GET' && url !== '/auth/token' && !customGetWithBodyData) {
        toast(`Sucesso! ${method} ${url}`, {
            type: 'success',
        });
    }
    return response;
}, (error) => {
    const {
        method,
        url,
        status,
        errors,
    } = getResponseLogData(error.response);
    toast(`Erro ${status}! ${method} ${url} ${errors[0].code}: ${errors[0].message}`, {
        type: 'error',
    });
    return Promise.reject(error);
});

mockMirage();

export default api;
