import { useCallback, useEffect, useMemo, useState } from 'react';
import { getVariablesAndOperations } from '../utils';
import { getStationsMeasures } from '../../../../../services/api';
import { useWeatherVariablesUnit } from '../../../../../hooks/use-weather-variables-unit';

export const useDownloadTableStates = ({
    variables,
    tableData,
    paginateParams,
    updateResponseData,
}) => {
    const [items, setItems] = useState(tableData?.data);
    const [sortOrder, setSortOrder] = useState('asc');
    const [isFetching, setIsFetching] = useState(false);
    const { getUnitLabel } = useWeatherVariablesUnit(variables);

    useEffect(() => {
        setItems(tableData.data);
    }, [tableData]);

    const sortedDatetimes = useMemo(() => {
        const datetimes = new Set();
        items.forEach((station) => {
            station.results.forEach((result) => {
                datetimes.add(result.datetime);
            });
        });
        return Array.from(datetimes).sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a) - new Date(b);
            } else {
                return new Date(b) - new Date(a);
            }
        });
    }, [items, sortOrder]);

    const getVariableName = useCallback(
        (variableKey) => {
            if (variables) {
                return variables.find(
                    (variable) => variable.key === variableKey
                ).label;
            }
        },
        [variables]
    );

    const toggleSortDirection = () => {
        setSortOrder((prevDirection) =>
            prevDirection === 'asc' ? 'desc' : 'asc'
        );
    };

    const fetchPageData = async (page) => {
        setIsFetching(true);

        const stationMeasureData = {
            ...paginateParams,
            page,
        };
        const responseData = await getStationsMeasures(stationMeasureData);
        updateResponseData(responseData);
        setIsFetching(false);
    };

    const variablesAndOperations = useMemo(() => {
        return items.map((station) => {
            const { variables, operations } =
                getVariablesAndOperations(station);
            return { stationId: station.station_id, variables, operations };
        });
    }, [items]);

    const stationColumns = useMemo(() => {
        return variablesAndOperations.map(({ stationId, operations }) => {
            const colSpan = Object.values(operations).reduce(
                (sum, ops) => sum + ops.size,
                0
            );
            return { stationId, colSpan };
        });
    }, [variablesAndOperations]);

    const variableColumns = useMemo(() => {
        return variablesAndOperations.flatMap(
            ({ stationId, variables, operations }) => {
                return variables.map((variable) => {
                    const unitLabel = getUnitLabel(variable);
                    return {
                        stationId,
                        variable,
                        unitLabel,
                        colSpan: operations[variable].size,
                    };
                });
            }
        );
    }, [getUnitLabel, variablesAndOperations]);

    const operationColumns = useMemo(() => {
        return variablesAndOperations.flatMap(
            ({ stationId, variables, operations }) => {
                return variables.flatMap((variable) =>
                    Array.from(operations[variable]).map((operation) => {
                        const unitLabel = getUnitLabel(variable);
                        return {
                            stationId,
                            variable,
                            operation,
                            unitLabel,
                        };
                    })
                );
            }
        );
    }, [getUnitLabel, variablesAndOperations]);

    const bodyDataColumns = useMemo(() => {
        return sortedDatetimes.map((datetime) => {
            return variablesAndOperations.flatMap(
                ({ stationId, variables, operations }) => {
                    const station = items.find(
                        (item) => item.station_id === stationId
                    );
                    const resultForDatetime =
                        station.results.find(
                            (result) => result.datetime === datetime
                        ) || {};
                    return variables.flatMap((variable) =>
                        Array.from(operations[variable]).map((operation) => {
                            const value =
                                resultForDatetime[variable]?.[operation];
                            const unitLabel = getUnitLabel(variable);
                            return {
                                stationId,
                                datetime,
                                variable,
                                operation,
                                value,
                                unitLabel,
                            };
                        })
                    );
                }
            );
        });
    }, [sortedDatetimes, variablesAndOperations, items, getUnitLabel]);

    return {
        items,
        sortedDatetimes,
        sortOrder,
        toggleSortDirection,
        getVariableName,
        fetchPageData,
        variablesAndOperations,
        isFetching,
        getUnitLabel,
        stationColumns,
        variableColumns,
        operationColumns,
        bodyDataColumns,
    };
};
