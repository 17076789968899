import React, { useState } from 'react';
import {
    FaChevronDown,
    FaChevronUp,
} from 'react-icons/fa';
import './styles.css';

function EntityBox({
    creating,
    title,
    setTitle,
    editable,
    onOpen,
    onClose,
    children,
    alwaysOpen,
    titleMaxLength,
    titleIconUser,
    titleIconWhatsapp,
}) {
    const [open, setOpen] = useState(creating || alwaysOpen);
    const [currentIconPopup, setCurrentIconPopup] = useState(null);

    const handleMouseOver = (popupText) => {
        setCurrentIconPopup(popupText);
    };
    
    const handleMouseOut = () => {
        setCurrentIconPopup(null);
    };

    function openFunction() {
        if (creating || alwaysOpen) {
            return;
        }
        const functionToExecute = open ? onClose : onOpen;
        functionToExecute && functionToExecute();
        setOpen(!open);
    }

    return (
        <div className="entity-box">
            <div
                className={`entity-box-header ${alwaysOpen ? 'not-clickable' : ''}`}
                onClick={openFunction}
            >
                <div className="entity-box-title">
                    {(titleIconUser || titleIconWhatsapp) && (
                        <div className="entity-box-title-icons">
                            <>
                                {titleIconUser && (
                                    <div className="entity-box-icon-container">
                                        <titleIconUser.icon
                                            key={titleIconUser.icon.name}
                                            onMouseOver={() => {handleMouseOver(titleIconUser.popup)}}
                                            onMouseOut={handleMouseOut}
                                            className={titleIconUser.className}
                                        />
                                    </div>
                                )}
                                {titleIconWhatsapp && (
                                    <div className="entity-box-icon-container">
                                        <titleIconWhatsapp.icon
                                            key={titleIconWhatsapp.icon.name}
                                            onMouseOver={() => {handleMouseOver(titleIconWhatsapp.popup)}}
                                            onMouseOut={handleMouseOut}
                                            className={titleIconWhatsapp.className}
                                        />
                                    </div>
                                )}
                            </>
                        </div>
                    )}
                    {currentIconPopup && (
                        <div className='popup-info'> {currentIconPopup} </div>
                    )}
        
                    {(editable && open) || (setTitle && creating)
                        ? (
                            <input
                                value={title}
                                placeholder="Nome"
                                onChange={(event) => setTitle(event.target.value)}
                                onClick={(event) => event.stopPropagation()}
                                maxLength={titleMaxLength}
                            />
                        )
                        : title
                    }
                </div>
                {!alwaysOpen && (open ? <FaChevronUp /> : <FaChevronDown />)}
            </div>
            {open && (
                <div className="entity-box-content">
                    {children}
                </div>
            )}
        </div>
    );
}

export default EntityBox;
