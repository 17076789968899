import datetimeUtils from '../../../../../utils/datetime';
const functions = {
    min: {
        label: 'Mínima',
    },
    max: {
        label: 'Máxima',
    },
    sum: {
        label: 'Acumulado',
    },
    avg: {
        label: 'Média',
    },
    mode: {
        label: 'Moda',
    },
    median: {
        label: 'Mediana',
    },
    abs: {
        label: 'Original',
    },
    max_datetime: {
        label: 'Hora da máxima',
    },
    min_datetime: {
        label: 'Hora da mínima',
    },
};

function getOperationLabelFormatted(operationKey) {
    return operationKey === 'abs' ? '' : `(${getOperationLabel(operationKey)})`;
}

const getVariablesAndOperations = (station) => {
    const variables = new Set();
    const operations = {};

    station.results.forEach((result) => {
        Object.keys(result).forEach((key) => {
            if (key !== 'datetime') {
                variables.add(key);

                if (!operations[key]) {
                    operations[key] = new Set(Object.keys(result[key]));
                } else {
                    Object.keys(result[key]).forEach((op) =>
                        operations[key].add(op)
                    );
                }
            }
        });
    });

    return { variables: Array.from(variables), operations };
};

function getOperationLabel(operationName) {
    return functions[operationName]?.label || '-';
}

function formatLocalOrUtcDateTime(dateTimeString, isLocalTime = true) {
    if (isLocalTime) {
        return datetimeUtils.getFormatDatetimeFromDatetime(
            dateTimeString,
            false,
            '-'
        );
    } else {
        return ` ${datetimeUtils.getInputFormatDatetimeFromDatetime(
            dateTimeString,
            true
        )}Z`;
    }
}

export {
    getVariablesAndOperations,
    getOperationLabel,
    getOperationLabelFormatted,
    formatLocalOrUtcDateTime,
};
