import DownloadForecasts from './subpages/forecasts/page';
import DownloadStationMeasures from './subpages/station-measures/page';

export const subpages = [
    {
        id: 'previsoes',
        title: 'Previsões',
        component: DownloadForecasts,
        setting: 'FORECASTS_DOWNLOAD',
    },
    {
        id: 'medidas-estacoes',
        title: 'Estações',
        component: DownloadStationMeasures,
        setting: 'STATION_MEASUREMENTS_DOWNLOAD',
    },
];
