import { useMemo } from 'react';
import { getOperationLabelFormatted } from '../utils';
import { formatLocalOrUtcDateTime } from '../utils/index';

import datetimeUtils from '../../../../../utils/datetime';

export function useMeasuresChartStates(
    response,
    weatherVariables,
    selectedStations,
    isLocalTime
) {
    const pointStyles = {
        precipitation: 'circle',
        wind_gust: 'triangle',
        atmospheric_pressure: 'rect',
        dew_point: 'rectRot',
        solar_radiation: 'dash',
        thermal_sensation: 'star',
        temperature: 'cross',
        relative_humidity: 'crossRot',
        wind_speed: 'rectRounded',
    };

    const chartData = useMemo(() => {
        if (!response || !response.data || !weatherVariables) {
            return { labels: [], datasets: [] };
        }

        const getDistinctColor = (stationIndex) => {
            const hueStep = 360 / Object.keys(selectedStations).length;
            const hue = (stationIndex * hueStep) % 360;
            const saturation = 50 + ((stationIndex * 5) % 10);
            const lightness = 75 + ((stationIndex * 3) % 5);
            return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        };

        const variables = [];
        const groupedResults = {};

        response.data.forEach((station) => {
            station.results.forEach((measure) => {
                const { datetime, ...operations } = measure;

                if (!groupedResults[datetime]) {
                    groupedResults[datetime] = { datetime };
                }

                Object.keys(operations).forEach((key) => {
                    if (!variables.find((v) => v.key === key)) {
                        const weatherVariable = weatherVariables.find(
                            (v) => v.key === key
                        );
                        if (weatherVariable) {
                            variables.push(weatherVariable);
                        } else {
                            variables.push({
                                key: 'no_measure',
                                label: 'Sem Medição',
                            });
                        }
                    }

                    Object.keys(operations[key]).forEach((opKey) => {
                        const value = operations[key][opKey];
                        groupedResults[datetime][
                            `${station.station_id}_${key}_${opKey}`
                        ] = value;
                    });
                });
            });
        });

        const content = Object.values(groupedResults).sort(
            (a, b) => new Date(a.datetime) - new Date(b.datetime)
        );

        if (variables.length === 0) {
            return { labels: [], datasets: [] };
        }

        variables.sort((a, b) => a.label.localeCompare(b.label));

        const labels = [
            ...new Set(
                content.map((item) =>
                    formatLocalOrUtcDateTime(item.datetime, isLocalTime)
                )
            ),
        ];

        const datasets = response.data.flatMap((station, stationIndex) => {
            const color = getDistinctColor(stationIndex);
            return variables.flatMap((variable, index) => {
                const variableKey = variable.key;
                const variableData = station.results.filter(
                    (item) => item[variableKey]
                );
                const data = labels.map((label) => {
                    const item = variableData.find(
                        (d) =>
                            formatLocalOrUtcDateTime(
                                d.datetime,
                                isLocalTime
                            ) === label
                    );
                    return item ? item[variableKey] : null;
                });

                const pointStyle = pointStyles[variableKey] || 'circle';

                return Object.keys(variableData[0]?.[variableKey] || {}).map(
                    (opKey) => {
                        const formattedData = data.map((d) => {
                            if (
                                d &&
                                (opKey.includes('min_datetime') ||
                                    opKey.includes('max_datetime'))
                            ) {
                                return datetimeUtils.showOnlyHours(d[opKey]);
                            }
                            return d ? d[opKey] : null;
                        });
                        return {
                            label: `${selectedStations[station.station_id]} - ${
                                variable.label
                            } ${getOperationLabelFormatted(opKey)}`,
                            backgroundColor: color,
                            data: formattedData,
                            borderColor: color,
                            borderWidth: 1.5,
                            variableKey: variableKey,
                            hidden: true,
                            pointStyle: pointStyle,
                            pointRadius: 3,
                            pointHoverRadius: 5,
                        };
                    }
                );
            });
        });

        if (datasets.length === 0) {
            datasets.push({
                label: 'Sem dados disponíveis',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                data: labels.map(() => null),
                borderColor: 'rgba(0, 0, 0, 0.1)',
                borderWidth: 1.5,
                hidden: false,
            });
        } else {
            datasets[0].hidden = false;
        }

        return { labels, datasets };
    }, [
        response,
        weatherVariables,
        isLocalTime,
        pointStyles,
        selectedStations,
    ]);

    return { chartData };
}
