import baseApi from '../../base';

export async function getUserRoles(userId) {
    const result = await baseApi.get(`/manage/users/${userId}/roles`);
    return result.data;
}

export async function getUsers() {
    const result = await baseApi.get('/manage/users');
    return result.data;
}

export async function postInviteUser(userId) {
    const result = await baseApi.post(`/manage/users/${userId}/invite`);
    return result.data;
}

export async function postManageUsers(user) {
    const result = await baseApi.post(`/manage/users`, user);
    return result.data;
}

export async function putManageUserRoles(userId, roles) {
    const result = await baseApi.put('/manage/roles/grant-only', {
        user_id: userId,
        role_ids: roles,
    });
    return result.data;
}

export async function putManageUsers(userId, user) {
    const result = await baseApi.put(`/manage/users/${userId}`, user);
    return result.data;
}
