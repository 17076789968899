import React from 'react';

import './styles.css';

function CheckboxFilledInput({
    id,
    className,
    value,
    onChange,
    disabled,
}, ref) {
    return (
        <span className={`${className} checkbox-filled-input-container ${disabled ? 'disabled' : ''}`}>
            <input
                type="checkbox"
                id={id}
                defaultChecked={value}
                onChange={onChange || (() => {})}
                disabled={disabled}
                ref={ref}
            />
        </span>
    );
}

export default React.forwardRef(CheckboxFilledInput);
