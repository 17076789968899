import React, { useState } from 'react';
import {
    FaPlus,
    FaCheckCircle,
    FaTimesCircle,
    FaTimes,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../contexts/auth';

import './styles.css';

function EntityRoles({
    entityRoles,
    setEntityRoles,
    rolesList,
}) {
    const {
        isMaster,
    } = useAuth();

    const rolesFiltered = isMaster
        ? rolesList
        : rolesList.filter(({ key }) => key !== 'MASTER');

    const [selectingRole, setSelectingRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    function removeEntityRole(roleId) {
        setEntityRoles(entityRoles.filter(id => id !== roleId));
    }

    function confirmAddRoleToEntity() {
        cancelAddRoleToEntity();
        setEntityRoles([ ...entityRoles, selectedRole ]);
    }

    function cancelAddRoleToEntity() {
        setSelectedRole(null);
        setSelectingRole(false);
    }

    return (
        <div className="entity-roles-container">
            {entityRoles.map(id => {
                const { key, description } = rolesList.find(r => r?.id === id);
                return (
                    <span
                        key={id}
                        className="entity-role"
                    >
                        {`${description} (${key})`}
                        {(key !== 'MASTER' || isMaster) && (
                            <Link
                                to="#"
                                className="entity-role-remove-btn"
                                onClick={() => removeEntityRole(id)}
                            >
                                <FaTimes />
                            </Link>
                        )}
                    </span>
                );
            })}
            {selectingRole
                ? (
                    <select
                        defaultValue="0"
                        onChange={event => setSelectedRole(parseInt(event.target.value))}
                    >
                        <option value="0" disabled>Selecione</option>
                        {rolesFiltered
                            .filter(r => r.key && !entityRoles.includes(r.id))
                            .map(({ id, key, description }) => (
                                <option key={id} value={id}>
                                    {`${description} (${key})`}
                                </option>
                            ))}
                    </select>
                )
                : (
                    <Link
                        to="#"
                        className="entity-role-add-btn"
                        onClick={() => setSelectingRole(true)}
                    >
                        <FaPlus />
                    </Link>
                )}
            {selectedRole && (
                <Link
                    to="#"
                    onClick={confirmAddRoleToEntity}
                >
                    <FaCheckCircle className="entity-role-confirm-add-btn" />
                </Link>
            )}
            {selectingRole && (
                <Link
                    to="#"
                    onClick={cancelAddRoleToEntity}
                >
                    <FaTimesCircle className="entity-role-cancel-add-btn" />
                </Link>
            )}
        </div>
    );
}

export default EntityRoles;
