import baseApi from '../../../base';

export async function getWhatsappBotUsers() {
    const result = await baseApi.get(`/manage/bot/whatsapp/users`);
    return result.data;
}

export async function postWhatsappBotUser(userId, user) {
    const result = await baseApi.post('/manage/bot/whatsapp/users', {
        ...user,
        cronos_user_id: userId,
    });
    return result.data;
}
