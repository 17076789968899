import { useMemo } from 'react';
import { useAuth } from '../contexts/auth';

export const useWeatherVariablesUnit = (weatherVariables) => {
    const { settingsSet } = useAuth();
    const { VARIABLES_UNITS } = { ...settingsSet };

    const variablesUnits = useMemo(() => {
        if (!weatherVariables) {
            return {};
        }
        const unitsByVariableKey = (VARIABLES_UNITS || []).reduce(
            (result, value) => {
                const [variableId, unitId] = value.split(',').map(Number);
                const variable = weatherVariables.find(
                    ({ id }) => id === variableId
                );
                const unit = variable.type.units.find(
                    ({ id }) => id === unitId
                );
                if (unit) {
                    result[variable.key] = unit;
                }
                return result;
            },
            {}
        );
        weatherVariables.forEach(({ key, type }) => {
            if (key in unitsByVariableKey) {
                return;
            }
            unitsByVariableKey[key] = type.units.find(
                ({ formula }) => formula.trim() === 'x'
            );
        });
        return unitsByVariableKey;
    }, [VARIABLES_UNITS, weatherVariables]);

    const getUnitLabel = (variableKey) => {
        return variablesUnits[variableKey]?.label || '';
    };

    return { variablesUnits, getUnitLabel };
};
