import { useState, useRef, useMemo, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { FaUserCheck, } from 'react-icons/fa';
import {
    RiMailCheckFill,
    RiMailCloseFill
} from 'react-icons/ri';

import { IoLogoWhatsapp } from "react-icons/io";

import { 
    getUserRoles,
    postInviteUser,
    postManageUsers,
    postWhatsappBotUser ,
    postWhatsappWelcomeMessage,
    putManageUserRoles,
    putManageUsers,
} from '../../../services/api';

import { useApiData } from '../../../contexts/api-data';

const PENDING_INVITATION_ICON = {
    icon: RiMailCloseFill,
    className: 'red',
    popup: 'Convite não enviado.',
};
const REGISTRATION_PENDING_ICON = {
    icon: RiMailCheckFill,
    className: 'orange',
    popup: 'Usuário não se registrou.',
};
const REGISTERED_USER_ICON = {
    icon: FaUserCheck,
    className: 'green',
    popup: 'Usuário cadastrado.',
};


const UNREGISTERED_WHATSAPP_ICON = {
    icon: IoLogoWhatsapp,
    className: 'red',
    popup: 'Não existe usuário bot.',
};
const WHATSAPP_PENDING_ICON = {
    icon: IoLogoWhatsapp,
    className: 'orange',
    popup: 'Usuário bot existe, porém não está inscrito ou ativo.',
};
const REGISTERED_WHATSAPP_ICON = {
    icon: IoLogoWhatsapp,
    className: 'green',
    popup: 'Usuário bot existe, está inscrito e ativo.',
};

export function useUserBoxState({ user, botUser }) {
    const {
        manageCustomers: {
            value: customersList,
        },
    } = useApiData();

    const [shouldSaveUser, setShouldSaveUser] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(user);
    const initialUser = useRef(user);
    const [title, setTitle] = useState(updatedUser?.name || user?.name || '');
    const [inviteCode, setInviteCode] = useState(updatedUser?.invite_code || user?.invite_code || '');

    const [shouldSaveBotUser, setShouldSaveBotUser] = useState(false);
    const [updatedBotUser, setUpdatedBotUser] = useState(botUser);
    const initialBotUser = useRef(botUser);

    const [shouldSaveUserRoles, setShouldSaveUserRoles] = useState(false);
    const [updatedUserRoles, setUpdatedUserRoles] = useState([]);
    const initialUserRoles = useRef([]);

    const [shouldSave, setShouldSave] = useState(false);

    const creating = !initialUser.current?.email;

    const customerName = useMemo(() => {
        const customer = customersList?.find((c) => c.id === updatedUser.customer_id);
        return customer?.name || '';
    }, [customersList, updatedUser.customer_id]);

    const titleIconUser = useMemo(() => {
        return updatedUser?.password_set
            ? REGISTERED_USER_ICON
            : (inviteCode
                ? REGISTRATION_PENDING_ICON 
                : PENDING_INVITATION_ICON
            );
    }, [updatedUser, inviteCode]);

    const titleIconWhatsapp = useMemo(() => {
        return !updatedBotUser?.phone_number
            ? UNREGISTERED_WHATSAPP_ICON
            : (!updatedBotUser?.subscribed || updatedBotUser?.inactive
                ? WHATSAPP_PENDING_ICON 
                : REGISTERED_WHATSAPP_ICON
            );
    }, [updatedBotUser]);

    async function saveUser () {
        if (shouldSaveUser) {
            const userToSend = {
                name: updatedUser?.name,
                email: updatedUser?.email,
                inactive: updatedUser?.inactive,
                customer_id: updatedUser?.customer_id,
            };

            const response = await (updatedUser?.id
                ? putManageUsers(updatedUser.id, userToSend)
                : postManageUsers(userToSend));

            setShouldSaveUser(false);

            if (response?.id) {
                initialUser.current = response;
                setUpdatedUser(response);
                return response;
            } else {
                initialUser.current = updatedUser;
                return updatedUser;
            }
        };
    };

    async function saveBotUser (userId) {
        if (shouldSaveBotUser) {
            await postWhatsappBotUser(updatedUser?.id || user?.id || userId, updatedBotUser)
            if (creating) {
                initialBotUser.current = {
                    ...updatedBotUser,
                    inactive: false,
                    subscribed: false,
                }
            } else {
                initialBotUser.current = updatedBotUser;
            }
            setShouldSaveBotUser(false);
        }
    }

    async function saveUserRoles (userId) {
        if (shouldSaveUserRoles) {
            await putManageUserRoles(updatedUser.id || userId, updatedUserRoles)
            initialUserRoles.current = updatedUserRoles;
            setShouldSaveUserRoles(false);
        };
    };

    async function saveAll () {
        const newUser = await saveUser();
        await saveBotUser(newUser?.id);
        await saveUserRoles(newUser?.id);
    }

    async function fetchUserRoles () {
        const response = await getUserRoles(updatedUser.id);
        initialUserRoles.current = response;
        setUpdatedUserRoles(response);
    };

    function handleChangeUser (newUser) {
        const keys = Object.keys(initialUser.current);
        for (const key of keys) {
            if (newUser[key] || newUser[key] === false) {
                setShouldSaveUser(initialUser.current[key] !== newUser[key]);
                if (key === 'inactive') {
                    setShouldSaveUser(initialUser.current.inactive !== newUser.inactive);
                }
            }
        }
        
        if(creating) {
            setShouldSaveUser(true);
        }

        setUpdatedUser({
            ...updatedUser,
            ...newUser,
        });
    };

    function handleChangeBotUser (newBotUser, key) {
        if (key === 'phone_number') {
            setShouldSaveBotUser((initialBotUser.current.phone_number !== newBotUser.phone_number) && newBotUser.phone_number);
        }

        if (key === 'inactive') {
            setShouldSaveBotUser(initialBotUser.current.inactive !== newBotUser.inactive);
        }

        if (key === 'subscribed') {
            setShouldSaveBotUser(initialBotUser.current.subscribed !== newBotUser.subscribed);
        }

        if (creating) {
            setShouldSaveBotUser(true);
        }

        setUpdatedBotUser({
            ...updatedBotUser,
            ...newBotUser,
        });
    };

    function handleChangeUserRoles (newUserRoles) {
        const newUserRolesArr = Object.values(newUserRoles);
        const newUserRolesSet = new Set(newUserRolesArr);
        const initialUserRolesSet = new Set (initialUserRoles.current);
        
        setShouldSaveUserRoles(
            newUserRolesSet.size !== initialUserRolesSet.size ||
            ![...newUserRolesSet].every(value => initialUserRolesSet.has(value)
        ));

        if(creating) {
            setShouldSaveUser(true);
        }

        setUpdatedUserRoles(newUserRolesArr);
    }

    async function inviteUser () {
        if (updatedUser.id) {
            await postInviteUser(updatedUser.id);
        }
    }

    async function resetBotMessage () {
        await postWhatsappWelcomeMessage(updatedUser.id);
    }

    useEffect(() => {
        if (user) {
            setUpdatedUser(user);
            initialUser.current = user;
        } 
    }, [user]);

    useEffect(() => {
        if (botUser?.phone_number) {
            setUpdatedBotUser(botUser);
            initialBotUser.current = botUser;
        } 
    }, [botUser]);

    useEffect(() => {
        setShouldSave(shouldSaveUser || shouldSaveBotUser || shouldSaveUserRoles);
    }, [shouldSaveUser, shouldSaveBotUser, shouldSaveUserRoles]);

    useEffect(() => {
        const newTitle = (updatedUser?.name && `${updatedUser?.name} (${customerName})`)
            || (updatedUser?.email && `${updatedUser?.email} (${customerName})`)
            || 'Preencher novo usuário'
        setTitle(newTitle);
    }, [updatedUser.name, updatedUser.email, customerName]);

    useEffect(() => {
        if (inviteCode) {
            const { exp } = jwt_decode(inviteCode);
            if (Date.now() >= exp*1000) {
                setInviteCode(null)
            }
        }
    }, [inviteCode]);

    return {
        creating,
        fetchUserRoles,
        handleChangeBotUser,
        handleChangeUser,
        handleChangeUserRoles,
        initialBotUser,
        inviteUser,
        inviteCode,
        resetBotMessage,
        saveAll,
        saveUser,
        setUpdatedUserRoles,
        shouldSave,
        title,
        titleIconUser,
        titleIconWhatsapp,
        updatedBotUser,
        updatedUser,
        updatedUserRoles,
    };
};
