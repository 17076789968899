import { useCallback, useEffect, useMemo, useState } from 'react';
const functions = {
    min: {
        label: 'Mínimo',
    },
    max: {
        label: 'Máximo',
    },
    sum: {
        label: 'Acumulado',
    },
    avg: {
        label: 'Média',
    },
    mode: {
        label: 'Moda',
    },
    median: {
        label: 'Mediana',
    },
};

const frequencyOptions = [
    {
        id: 1,
        label: '30 min',
        value: 30,
    },
    {
        id: 2,
        label: '1 hora',
        value: 60,
    },
    {
        id: 3,
        label: '2 horas',
        value: 120,
    },
    {
        id: 4,
        label: '3 horas',
        value: 180,
    },
    {
        id: 5,
        label: '6 horas',
        value: 360,
    },
    {
        id: 6,
        label: '12 horas',
        value: 720,
    },
    {
        id: 7,
        label: 'Diária',
        value: '1440',
    },
];

const operationsOptions = Object.keys(functions).map((key) => ({
    value: key,
    label: functions[key].label,
}));

const variablesToShow = [
    'precipitation',
    'wind_gust',
    'atmospheric_pressure',
    'dew_point',
    'solar_radiation',
    'thermal_sensation',
    'temperature',
    'relative_humidity',
    'wind_direction',
    'wind_speed',
];

export function useOperationsInputStates({
    variablesOperations,
    setVariablesOperations,
    frequency,
    setFrequency,
    variables,
}) {
    const [inputs, setInputs] = useState([]);
    const [shouldSave, setShouldSave] = useState(false);

    useEffect(() => {
        if (variablesOperations) {
            setInputs(variablesOperations);
        }
    }, [variablesOperations]);

    const variablesFiltered = useMemo(() => {
        return variables?.filter((variable) =>
            variablesToShow.includes(variable.key)
        );
    }, [variables]);

    const handleShouldSave = useCallback(() => {
        const newShouldSave =
            frequency !== 0
                ? inputs.length > 0 &&
                  inputs.every(
                      (input) =>
                          input.variableId !== 'add' &&
                          input.operations.length > 0
                  )
                : inputs.length > 0 &&
                  inputs.every((input) => input.variableId !== 'add');

        setShouldSave(newShouldSave);
    }, [frequency, inputs]);

    const addInput = useCallback(() => {
        const newInputs = inputs;
        newInputs.push({ variableId: 'add', operations: [] });
        setInputs(newInputs);
        handleShouldSave();
    }, [handleShouldSave, inputs]);

    const updateVariableOperations = useCallback(
        (newInputs) => {
            const updatedOperations = newInputs.map((input) => ({
                variableId: input.variableId,
                operations: input.operations,
            }));
            setVariablesOperations(updatedOperations);
        },
        [setVariablesOperations]
    );

    const removeInput = useCallback(
        (index) => {
            const newInputs = inputs.filter((_, i) => i !== index);
            setInputs(newInputs);
            updateVariableOperations(newInputs);
        },
        [inputs, updateVariableOperations]
    );

    const handleVariableChange = useCallback(
        (index, variableId) => {
            const newInputs = [...inputs];
            newInputs[index].variableId = variableId;
            setInputs(newInputs);
            updateVariableOperations(newInputs);
        },
        [inputs, updateVariableOperations]
    );

    const handleOperationsChange = useCallback(
        (index, operations) => {
            const newInputs = [...inputs];
            newInputs[index].operations = operations;
            setInputs(newInputs);
            updateVariableOperations(newInputs);
        },
        [inputs, updateVariableOperations]
    );
    useEffect(() => {
        handleShouldSave();
    }, [handleShouldSave, inputs]);

    return {
        inputs,
        operationsOptions,
        addInput,
        removeInput,
        handleVariableChange,
        handleOperationsChange,
        shouldSave,
        frequencyOptions,
        variablesFiltered,
    };
}
