import React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa6';
import Link from 'react-router-dom/Link';
import { MultipleSelectionInput } from '../../../../../components/common/custom-inputs';

import './styles.css';

import { useOperationsInputStates } from './states';
import BaseBtn from '../../../../../components/common/btn/base-btn';
import GetBtn from '../../../../../components/common/btn/get-btn';

export function OperationsInput({
    variables,
    closePopup,
    setVariablesOperations,
    variablesOperations,
    frequency,
    setFrequency,
}) {
    const {
        inputs,
        handleVariableChange,
        handleOperationsChange,
        operationsOptions,
        removeInput,
        addInput,
        shouldSave,
        frequencyOptions,
        variablesFiltered,
    } = useOperationsInputStates({
        setVariablesOperations,
        variablesOperations,
        frequency,
        variables,
        setFrequency,
    });

    return (
        <div className="download-station-measures-variables-operations-container">
            <div className="download-station-measures-variables-operations-header">
                <div className="download-station-measures-variables-operations-frequency">
                    <span className="download-station-measures-variables-operations-frequency-title">
                        Frequência dos dados(Opcional):
                    </span>
                    <select
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            setFrequency(value);
                        }}
                        className="download-station-measures-variables-operations-frequency-select"
                        value={frequency}
                    >
                        <option value={0}>Original</option>
                        {frequencyOptions.map(({ id, label, value }) => (
                            <option key={id} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
                <span style={{ display: 'block' }}>
                    <span className="station-measures-download-filters-required">
                        *
                    </span>
                    Selecione a variável e as operações desejadas.
                </span>
            </div>
            {inputs.map((input, index) => (
                <div
                    key={index}
                    className="download-station-measures-variables-operations-input"
                >
                    <div className="form-group">
                        <label className="download-station-measures-variables-operations-title">
                            Variável:
                        </label>
                        <select
                            id={`variables-id-${index}`}
                            value={input.variableId}
                            onChange={(e) =>
                                handleVariableChange(index, e.target.value)
                            }
                            className="download-station-measures-variables-operations-select"
                        >
                            <option value="">Selecione</option>
                            {variablesFiltered.map(({ label, id }) => (
                                <option
                                    key={id}
                                    value={id}
                                    disabled={inputs.some(
                                        (i) =>
                                            i.variableId === String(id) &&
                                            i.variableId !== input.variableId
                                    )}
                                >
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {frequency !== 0 && (
                        <div
                            className="form-group"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <label className="download-station-measures-variables-operations-title">
                                Operação:
                            </label>
                            <MultipleSelectionInput
                                defaultValue={input.operations}
                                setValue={(operations) =>
                                    handleOperationsChange(index, operations)
                                }
                                showOnlyItemsAmount={
                                    input.operations.length > 1 ? true : false
                                }
                                options={operationsOptions}
                                onClick={(e) => e.stopPropagation()}
                                className={'download-stations-list'}
                            />
                        </div>
                    )}
                    <Link to="#" onClick={() => removeInput(index)}>
                        <FaTrash />
                    </Link>
                </div>
            ))}

            <Link to="#" onClick={addInput} className="array-setting-add-btn">
                <FaPlus />
            </Link>

            <div className="download-station-measures-variables-operations-btn">
                <GetBtn
                    label="Salvar"
                    isAllowed={() => shouldSave}
                    onClick={() => {
                        if (!shouldSave) return;
                        setVariablesOperations(inputs);
                        closePopup();
                    }}
                />

                <BaseBtn
                    className="cancel-btn"
                    label="Cancelar"
                    onClick={() => {
                        closePopup();
                    }}
                />
            </div>
        </div>
    );
}
